import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { AssignVehiclesToGroupContainer } from "useCases/containers/AssignVehiclesToGroupContainer";
import { LoadingSpinner } from "../Abstractions/LoadingSpinner";
import { UpdateGroupContainer } from "../../useCases/containers/UpdateGroupContainer";
import { GetGroupContainerState } from "../../useCases/containers/GetGroupContainerState";
import { ContainersTableSummary } from "./ContainersTableSummary";

export function GroupContainerTable({ groupContainers, isActivePage, summary }) {
  const [activeGroupContainerId, setActiveGroupContainerId] = useState();
  const [activeAction, setActiveAction] = useState();

  useEffect(() => {
    if(!isActivePage){
      setActiveAction(null);
    }
  }, [isActivePage]);

  return (
    <Table hover>
      <thead>
      <tr>
        <th>Nazwa</th>
        <th>Suma ilości bazowej</th>
        <th>Suma ilości zużytych</th>
        <th>Suma pozostałych</th>
        <th>Akcje</th>
      </tr>
      </thead>
      <tbody>
      <LoadingSpinner isLoading={groupContainers.loading}/>
      {groupContainers.length > 0 &&
        groupContainers.map((groupContainer) => (
          <tr
            key={groupContainer.id}
            onClick={() => setActiveGroupContainerId(groupContainer.id)}
          >
            <td>{groupContainer.type}</td>
            <td>{groupContainer.totalInitializeCount}</td>
            <td>{groupContainer.totalUsedCount}</td>
            <td>{groupContainer.totalRemainingCount}</td>
            <td>
              <UpdateGroupContainer
                groupContainer={groupContainer}
                onShow={() => setActiveAction("updateContanier")}
              />
              <AssignVehiclesToGroupContainer
                groupContainer={groupContainer}
                activeGroupContainerId={activeGroupContainerId}
                onShow={() => setActiveAction("assignVehicles")}
                isFormActive={isActivePage && activeAction === "assignVehicles"}
              />
              <GetGroupContainerState
                container={groupContainer}
                activeGroupContainerId={activeGroupContainerId}
                onShow={() => {
                  setActiveGroupContainerId(groupContainer.id)
                  setActiveAction("getGroupContainerState"); }}
                isFormActive={isActivePage && activeAction === "getGroupContainerState"}
              />
            </td>
          </tr>
        ))}
      <ContainersTableSummary headerColSpan={1} summary={summary}/>
      </tbody>
    </Table>
  );
}
