import { Tab, Tabs } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { GetUsers } from "useCases/accounts/GetUsers";
import { GetContainers } from "useCases/containers/GetContainers";
import { GetContractors } from "useCases/contractors/GetContractors";
import { GetDrivers } from "useCases/drivers/GetDrivers";
import { GetVehicles } from "useCases/vehicles/GetVehicles";
import { GetWastes } from "useCases/wastes/GetWastes";
import { GetGroupContainers } from "../../useCases/containers/GetGroupContainers";
import { GetSalesRepresentatives } from "../../useCases/salesRepresentatives/GetSalesRepresentatives";
import { GetServices } from "../../useCases/services/GetServices";
import { GetHolidays } from "../../useCases/holidays/GetHolidays";
import { useSelector } from "react-redux";
import { USER_ROLES } from "../../domain/UserRoles";

export function Configuration() {
  const [searchParams, setSearchParams] = useSearchParams();
  const details = useSelector((x) => x.auth.details);
  const { role } = details || {};
  const typeParam = "type";
  const defaultTab = "contractors";

  const onChange = (value) => {
    setSearchParams({ [typeParam]: value });
  };

  const key = searchParams.get(typeParam) ?? defaultTab;
  const tabParams = (pageKey) => {
    return {
      activeEventKey: key,
      pageEventKey: pageKey,
      isActive: key === pageKey,
    };
  };

  const tabs = [
    {
      name: "contractors",
      title: "Klienci",
      page: <GetContractors {...tabParams("contractors")} />,
    },
    {
      name: "users",
      title: "Użytkownicy",
      page: <GetUsers {...tabParams("users")} />,
      hide: role !== USER_ROLES.ADMIN && role !== USER_ROLES.SUPER_USER
    },
    {
      name: "drivers",
      title: "Kierowcy",
      page: <GetDrivers {...tabParams("drivers")} />,
    },
    {
      name: "vehicles",
      title: "Pojazdy",
      page: <GetVehicles {...tabParams("vehicles")} />,
    },
    {
      name: "group",
      title: "Grupy kontenerów",
      page: <GetGroupContainers {...tabParams("group")} />,
    },
    {
      name: "containers",
      title: "Podtypy kontenerów",
      page: <GetContainers {...tabParams("containers")} />,
    },
    {
      name: "wastes",
      title: "Towary",
      page: <GetWastes {...tabParams("wastes")} />,
    },
    {
      name: "services",
      title: "Usługi",
      page: <GetServices {...tabParams("services")} />,
    },
    {
      name: "salesRepresentatives",
      title: "Przedstawiciele handlowi",
      page: <GetSalesRepresentatives {...tabParams("salesRepresentatives")} />,
    },
    {
      name: "holidays",
      title: "Święta",
      page: <GetHolidays {...tabParams("holidays")} />,
    }
  ];

  return (
    <>
      <div className="mb-4">
        <h1>Administracja</h1>
      </div>
      <Tabs className="mb-3" activeKey={key} onSelect={(k) => onChange(k)}>
        {tabs.filter(x => !x.hide).map((x) => (
          <Tab key={x.name} eventKey={x.name} title={x.title}>
            {x.page ?? x.title}
          </Tab>
        ))}
      </Tabs>
    </>
  );
}
