import { contractorsActions } from "_store/contractors.slice";
import { useDispatch } from "react-redux";
import { ContractorForm } from "./Extensions/CreateContractor/ContractorForm";
import { useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../../_components/Abstractions/BackButton";
import { stringToDateForm } from "../../domain/Configuration";

export function UpdateContractor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { contractor } = state; // Read values passed on state

  async function onSubmit(payload) {
    const response = await dispatch(
      contractorsActions.update({ id: contractor.id, payload: { details: payload } })
    );

    if (!response.error) {
      navigate(`/contractors/${contractor.id}`);
    } else {
    }

    return response;
  }

  const { addresses, bdoDetails } = contractor;
  const allAddresses = addresses?.map((x) => ({
    ...x,
    wasteProcessingDecisionData: x.wasteProcessingDecisionData ? {
      ...x.wasteProcessingDecisionData,
      decisionDate: stringToDateForm(x.wasteProcessingDecisionData?.decisionDate)} : undefined,
    bdoDetails: x.bdoDetails ? { ...x.bdoDetails, contractorTypes: contractor.contractorTypes } : null,
    wastes: x.loadingWastes,
    loadingWastesIds: x.loadingWastes?.map((x) => x.wasteId),
    usedContainers: x?.usedContainers?.filter(x => !x.isDeleted).map((uc) => ({
      ...uc,
      wastes: uc.wastes.map(w => ({ ...w, count: uc.wastesCount?.[w.wasteId] ?? 0 })),
    })),
  }));
  const centralAddress = allAddresses?.find((x) => x.isCentral);
  const pickUpAddresses = allAddresses?.filter((x) => !x.isCentral);
  const defaultIntermediary = contractor.intermediaries?.find(
    (x) => x.isDefault
  );
  const defaultValues = {
    ...contractor,
    bdoDetails: {
      ...bdoDetails,
      additionalCompaniesBdo: bdoDetails?.additionalCompaniesBdo?.map(company => ({
        id: company.id,
        companyId: company.companyId,
        name: company.companyName,
        nip: company.taxId,
        registrationNumber: company.registrationNumber,
        addressRaw: company.addressCentral
      }))
    },
    isSalesRepresentativeDefault: contractor.salesRepresentative?.isDefault,
    centralAddress,
    addresses: pickUpAddresses ?? allAddresses,
    defaultIntermediaryId: defaultIntermediary?.intermediaryId
  };

  return (
    <div className="container">
      <BackButton className="mb-1">
        Powrót do szczegółów klienta
      </BackButton>
      <ContractorForm
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        isUpdate
      />
    </div>
  );
}
