import { Badge, Card, Tooltip } from "react-bootstrap";
import { BadgeDomain } from "../../../../_components/Abstractions/BadgeDomain";
import { SelectTable } from "../../../../_components/Abstractions/SelectTable";
import { TableDomain } from "../../../../_components/Abstractions/TableDomain";
import { Recycle } from "react-bootstrap-icons";

export function WastesTooltip() {
  const render = (props, wastes) => (
    <Tooltip {...props}>
      Towary:{" "}
      {wastes?.length > 0 ? (
        <ul>
          {wastes.map((x) => (
            <li key={x.wasteCode}>{`${x.internalName} - ${x.wasteCode}`}</li>
          ))}
        </ul>
      ) : (
        "-"
      )}
    </Tooltip>
  );

  return { render };
}

export function ContainerListDetails({ containers }) {
  if (containers === null || containers?.length === 0) {
    return <></>;
  }

  const ContainerList = () => {
    return containers?.map((x) => (
      <Card key={x.usedContainerId} className={x.isDeleted ? "light-grey" : ""}>
        <Card.Body>
          <Card.Title>
            {x.groupContainerType + " "}<Badge bg={x.isInvalidContainersState ? "danger" : "black"}>{x.totalCount}</Badge>
          </Card.Title>
          <hr/>
          <Card.Subtitle className="mb-2 text-muted">
            {x.subContainers.map((s, index) => <div key={index} className="form ">{s.containerName} x
              {" "}<Badge bg="secondary">{s.usedCount}</Badge>
              {s?.logs?.length > 0 && <GetSubContainersLogs subContainer={s} />}
            </div>)}
          </Card.Subtitle>
          {x.subContainers.length > 0 && <hr/>}
          <div className="mb-3">
            {x.wastes?.map(w => <div key={w.wasteId}>
              <BadgeDomain size="x-small">{w.wasteCode} - {w.internalName}</BadgeDomain>
              {x.wastesCount && <>x <Badge>{x.wastesCount?.[w.wasteId]}</Badge></>}
            </div>)}
          </div>
        </Card.Body>
      </Card>
      // <OverlayTrigger
      //   key={x.usedContainerId}
      //   placement="right"
      //   overlay={(e) => render(e, x.wastes)}
      // >
      //   <Button className="me-2 d-flex flex-column align-content-center" variant="primary">
      //     <div>
      //       {x.groupContainerType + " "}
      //       <Badge bg="secondary">{x.totalCount}</Badge>
      //     </div>
      //     <Card className="form-input h-100">
      //       <Card.Body>
      //         {x.subContainers.map(s => <div key={s.id} className="form ">{s.containerName} x <Badge bg="secondary">{s.usedCount}</Badge></div>)}
      //       </Card.Body>
      //     </Card>
      //   </Button>
      // </OverlayTrigger>
    ));
  };

  const ContainersCard = () => (
    <>
      <Card border="light">
        <Card.Header as="h5">Kontenery</Card.Header>
        <Card.Body className="d-flex gap-2">{ContainerList()}</Card.Body>
      </Card>
    </>
  );

  const GetSubContainersLogs = ({ subContainer }) => {
    const table = <UsedSubContainersLogsTable items={subContainer?.logs}/>;
    return <SelectTable
      table={table}
      modalSize="md"
      label={<BadgeDomain size="x-small" bg="success"><Recycle/></BadgeDomain>}
      modalTitle={`Historia wymian podtypu ${subContainer.containerName}`}
      skipSearchBar
      btnClassName="py-0 px-1"
      btnVariant="white"
      btnSize="xx-small"
      />
  }

  return <>{ContainersCard()}</>;
}

export function UsedSubContainersLogsTable({ items }) {
  const keyColumn = "id";
  const columns = [
    {
      name: "description",
      headerName: "Opis"
    }
  ];

  const props = {
    keyColumn,
    columns,
    items,
    selectable: false
  };
  return (
    <>
      <TableDomain {...props}></TableDomain>
    </>
  );
}
