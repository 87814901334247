import s from "../style.module.css";
import { DomainButton } from "../../Abstractions/DomainButton";
import { ArrowLeftCircleFill, ArrowRightCircleFill } from "react-bootstrap-icons";
import { getDatesForWeek } from "../../../_helpers/dateExtensions";
import { FilterForm } from "../../Forms/FilterForm";
import { TransactionType } from "../../../domain/TransactionType";
import { TransportType } from "../../../domain/TransportType";
import { YesNoItems } from "../../../domain/YesNoItems";
import { FilterControl } from "../../Forms/FilterControl";
import { FilterDropdown } from "../../Abstractions/FilterDropdown";
import { AddressFilterSelect, ContractorFilterSelect } from "../../Contractors/ContractorFilterSelect";
import { useFilterFormContext } from "../../Forms/FilterContext";
import { Card } from "react-bootstrap";
import { HookTypes, PickupTypes } from "../../../domain/VehicleType";
import { VehicleFilterSelect } from "../../Vehicles/VehicleSelect";
import { DriverFilterSelect } from "../../Drivers/DriverSelect";
import { OrderStatus } from "../../../domain/Orders/OrderStatus";
import { MultiSelectFilter } from "../../Abstractions/MultiSelect/MultiSelectFilter";
import { CheckListBoxFilter } from "../../CheckListBox/CheckListBoxFilter";
import { FilterSwitch } from "../../Abstractions/FilterSwitch";

export function OrderCalendarFilterBox({ currentDate, currentFilters, handleCalendarChange, handleFilterChange, refreshCalendar, setCurrentFilters, isCalendarView = true }) {
  const handlePreviousWeekChange = async() => {
    const dates = getDatesForWeek(true, currentDate, -1);
    handleCalendarChange(dates);
  };

  const handleNextWeekChange = async() => {
    const dates = getDatesForWeek(true, currentDate, 1);
    handleCalendarChange(dates);
  };

  const refreshView = () => refreshCalendar();

  return <Card className="pt-2 px-2 mb-2 sticky-top" style={{ top: "60px" }} >
      <div className={s.panel}>
        {isCalendarView ? <DomainButton onClick={handlePreviousWeekChange}> <ArrowLeftCircleFill/> Poprzedni</DomainButton> : <div/>}
        <FilterForm setCurrentFilters={setCurrentFilters} currentFilters={currentFilters} formControls={<OrderCalendarFilterBasic refreshView={refreshView} isCalendarView={isCalendarView} />} onSubmit={handleFilterChange}/>
        {isCalendarView ? <DomainButton onClick={handleNextWeekChange}>Następny <ArrowRightCircleFill/></DomainButton> : <div/>}
      </div>
  </Card>;
}

export function OrderCalendarFilterBasic({ refreshView, isCalendarView }) {
  const { currentFilters, clearFilters } = useFilterFormContext();
  const { pickupTypes } = currentFilters;

  return (
    <div>
      <div className="d-flex gap-2 align-items-center align-content-start justify-content-center w-100">
        <MultiSelectFilter name="orderStatuses" options={OrderStatus} title="Status"/>
        <MultiSelectFilter name="transactionTypes" options={TransactionType} title="Typ transakcji"/>
        {/*<FilterDropdown name="transactionType" items={TransactionType} label="Typ transakcji"></FilterDropdown>*/}
        <ContractorFilterSelect name="contractorId" saveWholeObject={false}/>
        <AddressFilterSelect name="addressId" saveWholeObject={false}/>
        <CheckListBoxFilter name="transportTypes" items={TransportType} label="Typ transportu" allowMultiple/>
        <FilterControl name="orderNumber" label="Nr. zlecenia"/>
        <FilterDropdown name="isTransfer" defaultValue="-" items={YesNoItems} label="Przerzut"/>
        <FilterDropdown name="isTransportInvoiceNeeded" defaultValue="-" items={YesNoItems} label="FV transport"/>
        <DomainButton onClick={refreshView}>Odśwież</DomainButton>
        <DomainButton onClick={clearFilters}>Wyczyść filtry</DomainButton>
      </div>
      <div className="d-flex gap-2 align-items-end align-content-start justify-content-center">
        {isCalendarView && <FilterSwitch name="includeOverdue" label="Dołącz zaległe"/>}
        {!isCalendarView && <>
          <FilterControl name="dateFrom" label="Data załadunku od" inputType="date"/>
          <FilterControl name="dateTo" label="Data załadunku do" inputType="date"/>
        </>}
        {isCalendarView && <FilterControl name="loadingDate" label="Data załadunku" inputType="date"/>}
        <DriverFilterSelect name="driverId" label="Kierowca EkoP" allowSearchWithoutType/>
        <CheckListBoxFilter name="pickupTypes" items={PickupTypes} label="Typ pojazdu" allowMultiple/>
        {pickupTypes?.includes("Hook") && <FilterDropdown name="hookType" items={HookTypes} label="Typ haku"/>}
        <VehicleFilterSelect name="vehicleId" label="Pojazd EkoP" allowSearchWithoutType/>
        {/*<FilterControl name="vehicleRegNumber" label="Numer rejestracyjny"/>*/}
        <ContractorFilterSelect name="transportContactorId" label="Przewoźnik" contractorType="Carrier" saveWholeObject={false}/>
        <FilterDropdown defaultValue="-" name="isLoading" items={YesNoItems} label="Załadunek"/>
        <FilterDropdown defaultValue="-" name="isContainersExchange" items={YesNoItems} label="Wymiana kontenera"/>
        <FilterDropdown defaultValue="-" name="isBdoIntegrated" items={YesNoItems} label="Zlecenie BDO"/>
      </div>
    </div>
  );
}