import { useEffect } from "react";
import { SelectSearcher } from "../Abstractions/MultiSearcher/SelectSearcher";
import { useServicesSearch } from "./useServicesSearch";

export function ContractorServiceSelect({ name, readonly }) {
  const { onSearchChange, items } = useServicesSearch();

  useEffect(() => {
    if (readonly) {
      return;
    }
    onSearchChange(null);
  }, [readonly]); // eslint-disable-next-line/react-hooks/exhaustive-deps

  return (
    <>
      <SelectSearcher
        name={name}
        label="Usługa"
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "serviceId", value: "name" }}
        placeholder="Wyszukaj usługę..."
        readonly={readonly}
        saveWholeObject
      />
    </>
  );
}
