import { useFieldArray, useFormContext } from "react-hook-form";
import { NumericControl } from "../../../../../_components/Forms/NumericControl";
import { CardView } from "../../../../../_components/Abstractions/CardView";
import { HorizontalView } from "../../../../../_components/Abstractions/HorizontalView";
import { WastesSelect } from "../WastesSelect";
import { DomainButton } from "../../../../../_components/Abstractions/DomainButton";

export function WastesRatePlanForm() {
  const { fields, append, remove } = useFieldArray({ name: "wastes" });
  const { watch, getFieldState } = useFormContext();
  const fieldState = getFieldState("wastes");
  const type = watch("type");
  const isPurchase = type === "Purchase";
  const GetFieldName = (fieldName, index) => `wastes[${index}].${fieldName}`;
  const addItem = () => {
    append({
      waste: null,
      price: null
    });
  };


  return <>
    <CardView headerText="Towary" headerTypo="h5">
      <div className="domain-control error mb-2">{fieldState?.error?.message}</div>
      {fields?.map((waste, index) => (
        <div key={waste.id}>
          <HorizontalView columnsSize={[7, 3, 2]}>
            <WastesSelect name={GetFieldName("waste", index)} label="Towar" skipInitLoading readonly={isPurchase}/>
            {/*<FormControl name={GetFieldName("displayDescription", index)} label="Towar" readonly/>*/}
            <NumericControl name={GetFieldName("price", index)} label="Cena [zł]"/>
            {!isPurchase && <DomainButton size="large" onClick={() => remove(index)} className="mt-2">Usuń</DomainButton>}
          </HorizontalView>
        </div>
      ))}
      {!isPurchase && <DomainButton onClick={addItem}>Dodaj towar</DomainButton>}
    </CardView>
  </>;
}

