import { useDispatch } from "react-redux";
import { useState } from "react";
import { servicesActions } from "../../_store/services.slice";

export function useServicesSearch() {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  const onSearchChange = async (searchTerm) => {
    const { payload } = await dispatch(servicesActions.getAll({ searchTerm }));
    setItems(payload.items);
  };

  return { onSearchChange, items }
}