import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { contractorsActions } from "../../../../../_store/contractors.slice";
import { ContractorRatePlanForm } from "./ContractorRatePlanForm";

export function CreateContractorRatePlan({ ratePlanId, contractorId, contractorTypes, intermediaries, ratePlan, setShowOffCanvas }) {
  const dispatch = useDispatch();
  const { contractorWastes } = useSelector((x) => x.contractors);
  const intermediariesDictionary = intermediaries
    ? Object.fromEntries(intermediaries?.map((x) => [x.intermediaryId, x.name]))
    : [];

  useEffect(() => {
    if (!contractorId || ratePlanId) {
      return;
    }

    const getWastes = async() => {
      await dispatch(contractorsActions.getWastes({ id: contractorId }));
    };

    getWastes();
  }, [contractorId]);

  const isSender = contractorTypes.includes("Sender");
  const isReceiver = contractorTypes.includes("Receiver");
  const isTypeEditable = isSender && isReceiver;
  const type = isSender ? "Purchase" : isReceiver ? "Sale" : undefined;

  const defaultValues = {
    contractorTypes,
    isTypeEditable,
    type: !isTypeEditable ? type : undefined,
    isIntermediaryPricing: !!ratePlan?.intermediaryId,
    ratePlanId,
    ...ratePlan,
    wastes: !ratePlanId ?
      !contractorWastes?.loading && contractorWastes?.map(x => ({ waste: x })) :
      ratePlan?.wastes.map(x => ({ ...x, price: x.price?.toFixed(2) })),
    services: ratePlan?.services.map(x => ({ ...x, price: x.price?.toFixed(2) })),
    intermediaries: intermediariesDictionary,
  };
  if (!contractorId || !defaultValues.wastes || defaultValues.wastes?.length === 0 || defaultValues.wastes?.loading) {
    return <p>Aby stworzyć cennik, należy najpierw zdefiniować towary w załadunku / kontenerach</p>
  }

  return <ContractorRatePlanForm contractorId={contractorId} defaultValues={defaultValues} setShowOffCanvas={setShowOffCanvas}/>;
}