import { DomainSwitch } from "../../../../../_components/Abstractions/DomainSwitch";
import { CardView } from "../../../../../_components/Abstractions/CardView";
import { useFormContext } from "react-hook-form";
import { Dropdown } from "../../../../../_components/Abstractions/Dropdown";
import { RatePlanType } from "../../../../../domain/RatePlanType";

export function DefinitionRatePlanForm() {
  const { watch, getValues } = useFormContext();
  const intermediares = getValues("intermediaries");
  const isTypeEditable = getValues("isTypeEditable");
  const isIntermediaryPricing = watch("isIntermediaryPricing");

  return <>
    <CardView headerText="Definicja cennika" headerTypo="h5">
      <Dropdown name="type" label="Typ cennika" items={RatePlanType} readonly={!isTypeEditable}/>
      <DomainSwitch name="isIntermediaryPricing" label="Cennik pośrednika" readonly={!intermediares || Object.keys(intermediares).length === 0}/>
      {isIntermediaryPricing && <Dropdown label="Pośrednik" items={intermediares} name="intermediaryId"/>  }
    </CardView>
  </>;
}