import { Accordion, Card, Table } from "react-bootstrap";
import React, { useRef } from "react";
import { BadgeDomain } from "../../../../_components/Abstractions/BadgeDomain";
import { useSelector } from "react-redux";
import { TransactionTypeBadge } from "../../../../_components/OrderViews/Components/TransactionTypeBadge";
import { DomainButton } from "../../../../_components/Abstractions/DomainButton";
import { ContractorPricingOffcanvas } from "../CreateContractor/ContractorRatePlans/ContractorRatePlanForm";
import { LoadingSpinner } from "../../../../_components/Abstractions/LoadingSpinner";
import { ContractorRatePlansHistory } from "./ContractorRatePlansHistory";
import { useReactToPrint } from "react-to-print";
import { Printer } from "react-bootstrap-icons";
import s from "./style.module.css"

export function ContractorRatePlansDetails() {
  const { contractorData: { id, name, ratePlans, intermediaries, contractorTypes } } = useSelector(x => x.contractors);
  const isLoading = !ratePlans || ratePlans?.loading;
  const intermediariesDictionary = intermediaries
    ? Object.fromEntries(intermediaries?.map((x) => [x.intermediaryId, x.name]))
    : [];
  const PricingTable = ({ name, items }) => {
    return <div className="w-100">
      {items?.length === 0 && <>{name} - Brak</>}
      {items?.length > 0 && <Table className="mb-3">
        <thead>
        <tr>
          <td style={{ width: "75%" }}>{name}</td>
          <td style={{ width: "25%" }}>Cena</td>
        </tr>
        </thead>
        <tbody>
        {items}
        </tbody>
      </Table>}
    </div>;
  };

  const formRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => formRef.current
  });

  return (
    <Card className={s.sectionCard}>
      <Card.Header as="h4">Cenniki</Card.Header>
      <Card.Body className="upper">
        <LoadingSpinner isLoading={ratePlans?.loading} as="div"/>
        <Accordion className={s.group} alwaysOpen>
          {!isLoading && ratePlans?.map((ratePlan, idx) => (
            <Accordion.Item eventKey={idx} key={ratePlan.id}>
              <Accordion.Header>
                <Card.Subtitle>
                  Cennik {ratePlan.intermediaryId ?
                  <>pośrednika <BadgeDomain bg="success">{intermediariesDictionary[ratePlan.intermediaryId]}</BadgeDomain></> : "klienta "}
                  <TransactionTypeBadge transactionType={ratePlan.type}/>
                </Card.Subtitle>
              </Accordion.Header>
              <Accordion.Body>
                <div className={`d-flex mb-2 ${s.printHide}`}>
                  <div className="d-flex gap-1">
                    <ContractorPricingOffcanvas
                      ratePlanId={ratePlan?.id}
                      contractorId={id}
                      contractorTypes={contractorTypes}
                      intermediaries={intermediaries}
                      btnTitle="Edytuj"
                      size="smaller"
                      btnSize="sm"
                      ratePlan={ratePlan}
                    />
                    <ContractorRatePlansHistory size="smaller" ratePlan={ratePlan}/>
                    <DomainButton size="smaller" variant="success" onClick={handlePrint}>Drukuj <Printer/></DomainButton>
                  </div>
                </div>
                <div ref={formRef} className={s.pageBreak}>
                  <Card.Subtitle className={`d-none ${s.printShow}`}>
                    Cennik {ratePlan.intermediaryId &&
                    <>pośrednika <BadgeDomain bg="success">{intermediariesDictionary[ratePlan.intermediaryId]}</BadgeDomain> dla</>} klienta {name} <TransactionTypeBadge transactionType={ratePlan.type}/>
                  </Card.Subtitle>
                  <div className="d-flex gap-4">
                    <PricingTable
                      name="Towary"
                      items={ratePlan.wastes?.map(wasteRatePlan => <tr key={wasteRatePlan.wasteId}>
                        <td>
                          <BadgeDomain bg={wasteRatePlan.isDefined ? "primary" : "danger"} size="x-small">{wasteRatePlan.waste?.displayDescription}</BadgeDomain>
                        </td>
                        <td>{wasteRatePlan.price?.toFixed(2)} zł</td>
                      </tr>)}/>
                    <div className="vr"></div>
                    <PricingTable
                      name="Usługi"
                      items={ratePlan.services?.map(serviceRatePlan => <tr key={serviceRatePlan.serviceId}>
                        <td>
                          <BadgeDomain size="x-small">{serviceRatePlan.service?.name}</BadgeDomain>
                        </td>
                        <td>{serviceRatePlan.price?.toFixed(2)} zł</td>
                      </tr>)}/>
                  </div>
                </div>
              </Accordion.Body>
              <Card/>
            </Accordion.Item>))}
        </Accordion>
      </Card.Body>
    </Card>
  );
}