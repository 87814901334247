import { wastesActions } from "_store/wastes.slice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SelectSearcher } from "../../../../_components/Abstractions/MultiSearcher/SelectSearcher";

export function WastesSelect({ name, readonly, skipInitLoading = false }) {
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const onSearchChange = async (searchTerm) => {
    const params = { searchQuery: searchTerm, pageNumber: 1, pageSize: 30 };
    const { payload } = await dispatch(wastesActions.search(params));
    setItems(payload?.items);
  };

  useEffect(() => {
    if(skipInitLoading){
      return;
    }

    onSearchChange(null);
  }, []); // eslint-disable-next-line/react-hooks/exhaustive-deps

  return (
    <>
      <SelectSearcher
        name={name}
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "wasteId", value: "displayDescription" }}
        label="Wybierz towar"
        readonly={readonly}
        saveWholeObject
      />
    </>
  );
}
